import Vue from "vue";
import { API } from "../../../services/API"

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    imovel_edicao: {},  // Imovel Selecionada para Edição
    imovel_selecionado: {}, 

    currentPage: 1,
    count_dados: 22,

    // Filtro na Tela de Consulta 
    filtro: {
      cod_imovel: null,
      nome_imovel: null,
      localizacao: null,
      area_construida: null,
    },
    // Abas do container 
    abas: [
      ['mdi-text-box-outline', 'Dados', '0'],
      ['mdi-cash-multiple', 'Orçamento', '0'],
      ['mdi-timer-sand-complete', 'Cronograma', '0'],
      ['mdi-tooltip-text-outline', 'Proposta', '0'],
      ['mdi-folder-multiple-plus-outline', 'Documentos', '0'],
      ['mdi-text-box-multiple-outline', 'Relatorios', '0'],
    ],
  }
}

var vm_store_Imovel = new Vue({

  data() {
    return initialState();
  },

  methods: {
    // resetData() {
    //   Object.assign(this.$data, initialState());
    // },

    // Métodos da Obra - Gets / Post / Put / Delete
    // Método - Get All
    // async ImoveisGet(p_params) {
    //   const resposta = await API.get("/imoveis", { params: { ...p_params } });
    //   // console.log('resposta', resposta)
    //   if (resposta) {
    //     this.dados = [];
    //     if (resposta.data.result != 'Não possui dados') {
    //       this.dados = resposta.data.result
    //       this.count_dados = resposta.data.result.length;
    //       return resposta.data.result
    //     }
    //   }
    // },
    // Método - Get Individual
    // async ImovelGet(p_params) {
    //   const resposta = await API.get(`/imovel`, { params: { ...p_params }});
    //   return resposta.data.result;
    // },
    // // Método - Post 
    // async ImovelPost(p_params) {
    //   const resp = await API.post(`/imoveis`, { params: { ...p_params }});
    //   return resp;
    // },
    // // Método - Put
    // async ImovelPut(p_params) {
    //   const resp = await API.put(`/imoveis/`, { params: { ...p_params }});
    //   return resp;
    // },
    // // Método - Delete
    // async ImovelDelete(p_params) {
    //   const resp = await API.delete(`/imoveis`, { params: { ...p_params }});
    //   return resp;
    // },


  },
  
  // watch: {
  //   // docDocumentos()           { this.atualiza_abas() },
  //   // docContrato()             { this.atualiza_abas() },
  //   // docTransferencia()        { this.atualiza_abas() },
  //   // docRenogociacao()         { this.atualiza_abas() },
  //   // docRescisao()             { this.atualiza_abas() },
  //   // docAutorizacaoEscritura() { this.atualiza_abas() },
  //   // docEscritura()            { this.atualiza_abas() },
  //   // docMensagens()            { this.atualiza_abas() }
  // },
})

export default vm_store_Imovel
